import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import Draggable from 'react-draggable';
import './file.scss';
import firebase from "firebase";
import parse from 'html-react-parser';
import Taskbar from "./taskbar";
import $ from "jquery";
import ImageGallery from 'react-image-gallery';
import { withTranslation } from 'react-i18next';

class File extends Component {
    constructor(props) {
        super(props);
        this.fileRef = React.createRef(null);
        this.imageRef = React.createRef(null);

        this.state = {
            opened: false,
            file: {},
            assignment: false,
            wrong: false,
            selected: "file",
            lastFile: false,
        }
    }

    checkCode = (event) => {
        event.preventDefault();
        const code = event.target.elements.code.value;

        const {file} = this.state;
        if (code === file.password) {
            event.target.reset();
            this.setState({opened: true});
        } else {
            this.setState({wrong: true})
        }
    };

    componentDidUpdate(prevProps) {
        const {fileId} = this.props.match.params;
        if (prevProps.match.params.fileId !== fileId) {
            this.fetchFile(fileId);
        }
    }

    componentDidMount() {
        if (localStorage.getItem('profileId')) {
            const fileId = this.props.match.params.fileId;

            if (fileId) {
                this.fetchFile(fileId)
            }
        } else {
            this.props.history.push('/login')
        }
    }

    closeLastFile() {
        this.setState({file: null, opened: null, finished: true});
        $("#start-menu").toggle('slide')
    }

    redirectToNextFile = () => {
        const {history} = this.props;
        const {nextFile} = this.state;

        localStorage.setItem('currentPage', nextFile);
        if (history) history.push(`/file/${nextFile}`);
    };

    fetchFile(fileId) {
        const db = firebase.firestore();
        const fileRef = db.collection("files").doc(fileId);

        fileRef.get().then((doc) => {
            if (doc.exists) {
                const file = doc.data();

                if(file.assignmentProfiles) {
                    file.assignmentProfiles.includes(localStorage.getItem('profileId')) ? this.setState({assignment: true}) : this.setState({assignment: false});
                }

                const nextPage = file.page + 1;
                let nextFile = null;

                db.collection("files").where("page", "==", nextPage)
                    .get()
                    .then((querySnapshot) => {
                        this.setState({opened: false, nextFile: null})
                        querySnapshot.forEach((doc) => {
                            if(doc.id) {
                                this.setState({nextFile: doc.id})
                            }
                        });
                    })
                    .catch(function (error) {
                        console.log("Error getting documents: ", error);
                    });

                this.setState({nextFile: nextFile, file: file, wrong: false})
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch(function (error) {
            console.log("Error getting document:", error);
        });
    }

    handleDrag(window) {
        this.setState({selected: window})
    }

    renderImages(fileImg) {
        const {selected} = this.state;
        const { t, i18n } = this.props;


        if(fileImg.length > 0) {
            const images = fileImg.map(image => {
                return {original: image.src, thumbnail: image.src}
            });

            return (
                <Draggable
                    nodeRef={this.imageRef.current}
                    axis="both"
                    handle=".imageHeader"
                    bounds=".filePage"
                    onStart={() => this.handleDrag("images")}
                    defaultPosition={{x: 0, y: 0}}>
                    <div className="imageBox" style={selected === "images" ? {zIndex: 5} : {zIndex: 4}}>
                        <div className="imageHeader">
                            <h3>{t('images')}</h3>
                            <img onClick={() => {
                                $(".imageBox").toggle('slide')
                            }} className="closeBtn" src="../close.png"/>
                        </div>
                        <div className="imageBody">
                            <ImageGallery showThumbnails={fileImg.length > 1} showPlayButton={false} showNav={false} items={images}/>
                        </div>
                    </div>
                </Draggable>)
        }

    }

    logOut() {
        localStorage.removeItem('currentPage');
        localStorage.removeItem("profileId");
        localStorage.removeItem("profileName");

        this.props.history.push('/login')
    }

    render() {
        const { t, i18n } = this.props;
        const {file, assignment, selected, nextFile, finished} = this.state;
        const fileImg = (file ? (assignment ? (i18n.language === "en-US" ? (file.imagesAssignment_en ? file.imagesAssignment_en : []) : (file.imagesAssignment ? file.imagesAssignment : [])) : (i18n.language === "en-US" ? (file.imagesInformation_en ? file.imagesInformation_en : []) : (file.imagesInformation ? file.imagesInformation : []))) : []);
        const fileUrl = i18n.language === "en-US" ? (file && file.document_en ? file.document_en.src : null) : (file && file.document ? file.document.src : null);
        return (
            <div className="filePage">
                {this.state.opened ? (<div className={"fileOpen"}>
                    <div className={"downloadBox"}>
                        <div className="downloadHeader">
                            <h3>{i18n.language === "en-US" ? file.title_en : file.title}</h3>
                        </div>
                        <div className="downloadBody">
                            <p>{t('gained_access_to')} "{i18n.language === "en-US" ? file.title_en : file.title}".</p>
                            {!nextFile ? (<div className={"showCollection"}>
                                <p>{t('last_file')}</p>
                                <button onClick={() => this.closeLastFile()} className="nextBtn">
                                    <span>{t('view_collection')}</span></button>
                            </div>): (<button onClick={() => this.redirectToNextFile()} className="nextBtn">
                                <span>{t('next_file')}</span></button>)}

                        </div>
                    </div>
                </div>) : null}
                {file ? (<Draggable
                    nodeRef={this.fileRef.current}
                    axis="both"
                    bounds=".filePage"
                    handle=".fileHeader"
                    onStart={() => this.handleDrag("file")}
                    defaultPosition={{x: 0, y: 0}}>
                    <div ref={this.fileRef.current} style={selected === "file" ? {zIndex: 5} : {zIndex: 4}} className="fileBox">
                        <div className="fileHeader">
                            <h3>{i18n.language === "en-US" ? (file.title_en ? file.title_en : "") : (file.title ? file.title : "")}</h3>
                            <img onClick={() => {
                                $(".fileBox").toggle('slide')
                            }} className="closeBtn" src="../close.png"/>
                        </div>
                        <div className="fileBody">
                            {i18n.language === "en-US" ? (assignment ? (file.descriptionAssignment_en ? parse(`${file.descriptionAssignment_en}`) : "") : (file.descriptionInformation_en ? parse(`${file.descriptionInformation_en}`) : "")) : (assignment ? (file.descriptionAssignment ? parse(`${file.descriptionAssignment}`) : "") : (file.descriptionInformation ? parse(`${file.descriptionInformation}`) : ""))}
                        </div>
                        <div className="decodeBar">
                            <p>{t('unlock_files')}</p>
                            <form className="inputForm" onSubmit={this.checkCode}>
                                <input name="code" style={this.state.wrong ? { boxShadow: '0 0 0 1px red'} : {}}
                                       placeholder={"code"}
                                       className="codeInput"/>
                                <button type="submit" className="submitBtn"><span>{t('check')}</span></button>
                            </form>
                        </div>
                    </div>
                </Draggable>) : null}
                {
                    this.renderImages(fileImg)
                }
                <Taskbar currentFile={file} finished={finished} images={fileImg} assignment={assignment}/>
            </div>
        )
    }

}
;

export default withRouter(withTranslation()(File));
