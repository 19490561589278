import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    withRouter
} from "react-router-dom";

import Login from './login';
import File from './file/file';
import AdminPage from "./admin/adminPage";
import firebase from 'firebase';
import {DB_CONFIG} from './config.js';

import './App.scss';
import {withTranslation} from "react-i18next";

firebase.initializeApp(DB_CONFIG);


class App extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
//set lang
        const {i18n, location} = this.props;
        const urlParams = new URLSearchParams(location.search);
        const lang = urlParams.get('lang');

        const previousLang = localStorage.getItem('lang');

        if (lang === "en" || (!lang && previousLang === "en")) {
            localStorage.setItem('lang', "en");
            i18n.changeLanguage('en-US')
        } else if (lang === "nl" || (!lang && previousLang === "nl")) {
            localStorage.setItem('lang', "nl");
            i18n.changeLanguage('nl-NL')
        }
    }

    renderBackground = () => (
        <div className="background">
            <img src="/under-pressure-bg.png"/>
        </div>
    );

    render() {
        return (
            <div className="App">
                <Router>
                    {/* A <Switch> looks through its children <Route>s and
                    renders the first one that matches the current URL. */}
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/login"/>
                        </Route>
                        <Route path="/login">
                            {this.renderBackground()}
                            <div className="contentWrapper">
                                <img className="bgLogo" src="/logoBW.png"/>
                                <div className="overlay">
                                    <img className="logo" src="../logo.png"/>
                                    <Login/>
                                </div>
                            </div>
                        </Route>
                        <Route path="/file/:fileId">
                            {this.renderBackground()}
                            <div className="contentWrapper">
                                <img className="bgLogo" src="/logoBW.png"/>
                                <div className="overlay">
                                    <img className="logo" src="../logo.png"/>
                                    <File/>
                                </div>
                            </div>
                        </Route>
                        <Route path="/admin">
                            <AdminPage/>
                        </Route>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default withRouter(withTranslation()(App));
