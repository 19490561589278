export const DB_CONFIG = {
    apiKey: "AIzaSyAvSiY6diUiCh2eB7o1No7z-5OAaqv3LhI",
    authDomain: "under-pressure-207be.firebaseapp.com",
    databaseURL: "https://under-pressure-207be.firebaseio.com",
    projectId: "under-pressure-207be",
    storageBucket: "under-pressure-207be.appspot.com",
    messagingSenderId: "193701949235",
    appId: "1:193701949235:web:dc6d4af51e259241b99846",
    measurementId: "G-HL0QH8MR4L"
};
