import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import './taskbar.scss';
import firebase from "firebase";
import $ from 'jquery';
import {withTranslation} from "react-i18next";

class Taskbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            time: "",
            startOpen: false
        }
    }

    componentDidMount() {
        $(document).ready(function () {
            $("#home-button").click(function () {
                $("#start-menu").toggle('slide');
            });
        });

        this.fetchFiles()
        setInterval(this.updateClock, 1000);
    }

    logoutUser = () => {
        this.props.history.push('/login')
        localStorage.removeItem('currentPage');
        localStorage.removeItem("profileId");
        localStorage.removeItem("profileName");
    };

    updateClock = () => {
        let currentTime = new Date();
        let currentHours = currentTime.getHours();
        let currentMinutes = currentTime.getMinutes();

        currentMinutes = (currentMinutes < 10 ? "0" : "") + currentMinutes;

        let timeOfDay = (currentHours < 12) ? "AM" : "PM";

        currentHours = (currentHours > 12) ? currentHours - 12 : currentHours;
        currentHours = (currentHours === 0) ? 12 : currentHours;

        let currentTimeString = currentHours + ":" + currentMinutes + " " + timeOfDay;
        this.setState({time: currentTimeString})

    }

    fetchFiles = () => {
        const db = firebase.firestore();
        db.collection("files").orderBy("page")
            .get()
            .then((querySnapshot) => {
                this.setState({files: querySnapshot.docs.map(doc => doc.data())});
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });
    };

    openFile = () => {
        $("#start-menu").toggle('slide')

        if(!$(".imageBox").is(':visible')) {
            $(".imageBox").toggle('slide');
        }

        if(!$(".fileBox").is(':visible')) {
            $(".fileBox").toggle('slide');
        }
    }

    renderFileButtons = (files, currentFile, i18n) => {
        const {finished} = this.props;

        if (files) {
            return files.map(file => {
                const href = finished || (currentFile.page > file.page) ? (i18n.language === "en-US" ? (file.document_en && file.document_en.src ? file.document_en.src : null) : (file.document && file.document.src ? file.document.src : null)) : null;

            return(
                <a href={href} target="_blank" download>
                    <li onClick={() => {
                        if (!finished && currentFile.page === file.page) {
                            this.openFile();
                        }
                    }} className={"start-menu-item " + (!finished && currentFile.page < file.page ? "disabled" : "")}><i
                        class={currentFile && currentFile.page  === file.page ? "fa fa-file" : (finished || (currentFile.page > file.page)) ? "fas fa-file-pdf" : "fa fa-file"}></i>{i18n.language === "en-US" ? file.title_en : file.title}
                    </li>
                </a>)
            })
        }
    };

    render() {
        const {time, files} = this.state;
        const {currentFile, images, t, i18n, finished} = this.props;
        const hasImages =  images.length > 0;

        return (
            <div className="taskbar" id="taskbar">
                <div id="home-button"><i class="far fa-circle"></i></div>
                {!finished ? (<div onClick={() => {
                    $(".fileBox").toggle('slide')
                }} className="fileBtn">{i18n.language === "en-US" ? (currentFile.title_en ? currentFile.title_en : "") : (currentFile.title ? currentFile.title : "")}</div>) : null}
                {hasImages ? <div onClick={() => {
                    $(".imageBox").toggle('slide')
                }} className="fileBtn">{t("IMAGES")}</div> : null}
                <div class="taskbar-tools">
                    <div id="time">{time}</div>
                    <div id="wifi"><i class="fa fa-wifi"></i></div>
                    <div id="battery"><i class="fa fa-battery-three-quarters"></i></div>
                </div>

                <div id="start-menu">
                    <div id="settings">
                        <ul>
                            <li onClick={() => this.logoutUser()} class="settings-item"><i class="fa fa-power-off"></i>
                            </li>
                        </ul>
                    </div>

                    <ul>
                        {this.renderFileButtons(files, currentFile, i18n)}
                    </ul>
                </div>
            </div>
        );
    }

}
;

export default withRouter(withTranslation()(Taskbar));
